/**
 * This file is only for adding fonts
*/

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/fonts/Inter/inter-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Inter/inter-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-500 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('/fonts/Inter/inter-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Inter/inter-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-600 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/fonts/Inter/inter-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Inter/inter-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/fonts/Inter/inter-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Inter/inter-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('/fonts/Work-sans/work-sans-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Work-sans/work-sans-500.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/fonts/Work-sans/work-sans-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Work-sans/work-sans-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/fonts/Work-sans/work-sans-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Work-sans/work-sans-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/fonts/Work-sans/work-sans-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Work-sans/work-sans-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-700 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/fonts/Roboto-mono/roboto-mono-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Roboto-mono/roboto-mono-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-600 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/fonts/Roboto-mono/roboto-mono-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Roboto-mono/roboto-mono-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-mono-500 - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('/fonts/Roboto-mono/roboto-mono-500.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Roboto-mono/roboto-mono-500.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/fonts/Roboto-mono/roboto-mono-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/Roboto-mono/roboto-mono-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
